import PropTypes from "prop-types"
import React from "react"

import { Link } from "gatsby"

const ExtendedLink = ({ to, text, className, children }) => {
  /* 
  This component checks where a link is pointed:
    For internal site links, Gatsby Link is used
    For external links, a normal a href tag is used with the tag opening in a new tab
  */

  //Add polyfill if needed
  if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, "startsWith", {
      value: function(search, rawPos) {
        var pos = rawPos > 0 ? rawPos | 0 : 0
        return this.substring(pos, pos + search.length) === search
      },
    })
  }

  return to.startsWith("/") ? (
    <Link title={text} to={to} className={className}>
      {children}
    </Link>
  ) : (
    <a
      href={to}
      title={text}
      target="_blank"
      rel="nofollow noopener noreferrer"
      className={className}
    >
      {children}
    </a>
  )
}

ExtendedLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default ExtendedLink
