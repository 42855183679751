import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import { CssBaseline } from "@material-ui/core"

import AppBar from "./navigation/AppBar"

const Header = () => (
  <header>
    <Helmet>
      <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="0d366142-2563-458d-8a44-7a3849b17ad8" type="text/javascript" async></script>
    </Helmet>
    <CssBaseline />
    <AppBar />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
