import React, { useState, useContext, useEffect, useRef } from "react"

import { Container, Grid } from "@material-ui/core"
import ArrowIcon from "@material-ui/icons/KeyboardArrowUpOutlined"

import { makeStyles } from "@material-ui/core/styles"
import colors from "../theme/colors"
import constants from "../theme/constants"

import SearchContext from "../../contexts/SearchContext"

import LandingPageLinks from "../../data/links-landingpages"
import ContentLinks from "../../data/links-content"
import SiteLinks from "../../data/links-site"

import FooterLinks from "./FooterLinks"
import FooterDetails from "./FooterDetails"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.grey["3"],
    paddingTop: "2rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "4rem",
    },
  },
  footer: {
    maxWidth: constants.content.maxWidth,
    paddingLeft: constants.content.paddingLeft,
    paddingRight: constants.content.paddingRight,
    margin: "auto",
  },
  footerDetails: {},
  footerLinks: {
    flexGrow: 1,
  },
  details: {},
  backToTop: {
    paddingTop: "1rem",
    paddingBottom: "3rem",
    textAlign: "right",
  },
  backToTopLink: {
    color: colors.base.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: "1rem",
    textDecoration: "none",
    margin: "0 auto 2rem",
    "&:hover": {
      color: colors.blue["0"],
      backgroundColor: "inherit",
    },
  },
  backToTopIcon: {
    width: "1.1rem",
    height: "0.8rem",
  },
}))

const resetScroll = () => event => {
  if (
    event.type === "keydown" &&
    (event.key === "Tab" || event.key === "Shift")
  ) {
    return
  }
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

const Footer = ({ data }) => {
  const searchContext = useContext(SearchContext)
  const {
    heroLoaded
  } = searchContext
  const classes = useStyles()

  return heroLoaded && (
    <footer className={classes.root}>
      <Container>
        <Grid
          className={classes.footer}
          container
          direction="row"
          justify="center"
        >
          <Grid className={classes.footerLinks} item>
            <FooterLinks
              landingPages={LandingPageLinks}
              contentPages={ContentLinks}
              sitePages={SiteLinks}
            />
          </Grid>
          <Grid className={classes.details} item>
            <FooterDetails data={data} />
          </Grid>
          <Grid
            item
            xs={12}
            onClick={resetScroll()}
            className={classes.backToTop}
          >
            <a className={classes.backToTopLink} onClick={resetScroll()}>
              <ArrowIcon
                className={classes.backToTopIcon}
                aria-label="Back to top"
              />
              YLÖS
            </a>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer
