import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Button from "@material-ui/core/Button"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Divider from "@material-ui/core/Divider"

import { makeStyles } from "@material-ui/core/styles"
import colors from "../theme/colors"

import ArrowIcon from "@material-ui/icons/KeyboardArrowDownOutlined"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    color: theme.palette.primary.contrastText,
    fontSize: "0.8rem",
    lineHeight: "1rem",
    fontWeight: "bold",
    textDecoration: "none",
    margin: "auto 0.5rem",
    "&:hover": {
      color: colors.blue["1"],
      backgroundColor: "inherit",
    },
  },
  buttonFocus: {
    color: colors.blue["1"],
  },
  buttonIcon: {
    width: "0.9rem",
    height: "1rem",
  },
  topLink: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.8rem",
    lineHeight: "1rem",
    fontWeight: "bold",
    textDecoration: "none",
    margin: "auto",
    "&:hover": {
      color: colors.blue["1"],
      backgroundColor: "inherit",
    },
  },
  topLinkLeft: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.8rem",
    lineHeight: "1rem",
    fontWeight: "bold",
    textDecoration: "none",
    margin: "auto",
    "&:hover": {
      color: colors.blue["1"],
      backgroundColor: "inherit",
    },
    paddingLeft: '50px'
  },
  menuEntry: {
    paddingTop: "0.2rem",
    paddingBottom: "0.2rem",
  },
  menuLink: {
    color: colors.base.black,
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.8rem",
    textDecoration: "none",
    "&:hover": {
      color: colors.blue["1"],
      backgroundColor: "none",
    },
  },
  divider: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
}))

export default function NavDropdown(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const handleToggle = event => {
    setOpen(!open)
  }

  const handleClose = () => {
    if (anchorEl.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const { text, baseUrl, baseText, menuItems, links } = props

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Button
        buttonRef={node => {
          setAnchorEl(node)
        }}
        aria-owns={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.button}
        focusVisibleClassName={classes.buttonFocus}
        disableRipple
        disableFocusRipple
      >
        {text.toUpperCase()}{" "}
        <ArrowIcon className={classes.buttonIcon} aria-label={text} />
      </Button>

      {/* Pooper needs keepMounted prop for SEO but it current breaks the styles TODO: Fix this */}
      <Popper open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {menuItems.map((items, index) => {
                    return (
                      <div key={index}>
                        {!!items.length && !!index && (
                          <Divider
                            className={classes.divider}
                            component="li"
                            variant="middle"
                          />
                        )}
                        {items.map((item, subIndex) => (
                          <MenuItem
                            className={classes.menuEntry}
                            key={`${index}-${subIndex}`}
                          >
                            <Link
                              className={classes.menuLink}
                              to={`${item.to.toLowerCase()}`}
                            >
                              {item.text}
                            </Link>
                          </MenuItem>
                        ))}
                      </div>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {links.map((link, index) => {
        if(index === 0){
          return (
            <Link key={index} className={classes.topLink} to={link.to}>
              {link.text.toUpperCase()}
            </Link>
          )
        }else{
          return (
            <Link key={index} className={classes.topLinkLeft} to={link.to}>
              {link.text.toUpperCase()}
            </Link>
          )
        }
      })}
    </div>
  )
}

NavDropdown.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  baseText: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        to: PropTypes.string,
        text: PropTypes.string,
      })
    )
  ),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

NavDropdown.defaultProps = {
  text: "Toimitilat",
  baseUrl: "toimitilat",
  baseText: "Kaikki toimitilat",
  menuItems: [],
  links: [],
}
