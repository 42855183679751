import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"

import Drawer from "@material-ui/core/Drawer"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Divider from "@material-ui/core/Divider"

import { makeStyles } from "@material-ui/core/styles"
import colors from "../theme/colors"

const useStyles = makeStyles(theme => ({
  root: {
    display: "inherit",
  },
  paper: {
    background: colors.grey[0],
  },
  menuButton: {
    width: "1.6rem",
    height: "1.6rem",
  },
  menu: {
    width: "250px",
  },
  menuEntry: {
    paddingTop: "0.2rem",
    paddingBottom: "0.2rem",
    minHeight: "auto",
  },
  menuLink: {
    color: colors.base.black,
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.85rem",
    textDecoration: "none",
    "&:hover": {
      color: colors.blue["1"],
      background: "none",
    },
  },
  boldLink: {
    fontWeight: "bold",
  },
  divider: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
}))

export default function NavDrawer(props) {
  const classes = useStyles()

  const [open, toggleOpen] = useState(false)

  const { menuItems, links } = props

  const toggleMenu = show => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    toggleOpen(show)
  }

  return (
    <React.Fragment>
      <IconButton color="inherit" aria-label="menu" onClick={toggleMenu(true)}>
        <MenuIcon className={classes.menuButton} />
      </IconButton>
      <Drawer
        classes={{ paper: classes.paper }}
        anchor="right"
        open={open}
        onClose={toggleMenu(false)}
      >
        <div
          className={classes.menu}
          role="presentation"
          onClick={toggleMenu(false)}
          onKeyDown={toggleMenu(false)}
        >
          <MenuList>
            {menuItems.map((items, index) => {
              return (
                <div key={index}>
                  {!!items.length && !!index && (
                    <Divider className={classes.divider} component="li" />
                  )}
                  {items.map((item, subIndex) => {
                    // For the syling, the top meju Item should be bold to highlight the section in the full Drawer list
                    const linkClasses =
                      index === 0 && subIndex === 0
                        ? `${classes.menuLink} ${classes.boldLink}`
                        : classes.menuLink
                    return (
                      <MenuItem
                        className={classes.menuEntry}
                        key={`${index}-${subIndex}`}
                        onClick={toggleMenu(false)}
                      >
                        <Link
                          className={linkClasses}
                          to={`${item.to.toLowerCase()}`}
                        >
                          {item.text}
                        </Link>
                      </MenuItem>
                    )
                  })}
                </div>
              )
            })}
            {!!links.length && (
              <Divider className={classes.divider} component="li" />
            )}
            {links.map((link, index) => (
              <MenuItem
                className={classes.menuEntry}
                key={index}
                onClick={toggleMenu(false)}
              >
                <Link
                  className={`${classes.menuLink} ${classes.boldLink}`}
                  to={link.to}
                >
                  {link.text}
                </Link>
              </MenuItem>
            ))}
          </MenuList>
        </div>
      </Drawer>
    </React.Fragment>
  )
}

NavDrawer.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  baseText: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        to: PropTypes.string,
        text: PropTypes.string,
      })
    )
  ),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

NavDrawer.defaultProps = {
  baseUrl: "toimitilat",
  baseText: "Kaikki toimitilat",
  menuItems: [],
  links: [],
}
