import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

import typography from "./typography"
import colors from "./colors"
import constants from "./constants"

let theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.grey["0"],
      contrastText: colors.base.black,
    },
    secondary: {
      main: colors.blue["1"],
    },
  },
  typography: {
    fontFamily: typography.defaultFontFamily,
    fontStyle: "normal",
    fontWeight: 300,
    htmlFontSize: 10,

    h1: typography.h1,
    h2: typography.h2,
    h3: typography.h3,
    h4: typography.h4,
    h5: typography.h5,
    h6: typography.h6,
    subtitle1: typography.intro,
    subtitle2: typography.xsmallBody,
    body1: typography.body,
    body2: typography.smallBody,
  },
})
/* Now theme can be used in override */
theme.overrides = {
  MuiCssBaseline: {
    "@global": {
      body: {
        maxWidth: "1440px",
        margin: "0 auto",
        //boxShadow: '0px 0px 60px 5px rgba(0,0,0,0.1)' // No shadow :)
      },
    },
  },
  MuiContainer: {
    maxWidthXs: {},
    maxWidthSm: {},
    maxWidthMd: {
      maxWidth: constants.content.maxWidth,
      [theme.breakpoints.up("md")]: {
        maxWidth: constants.content.maxWidth,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: constants.content.maxWidth,
      },
      [theme.breakpoints.up("xl")]: {
        maxWidth: constants.content.maxWidth,
      },
    },
    maxWidthLg: {
      maxWidth: constants.content.maxWidth,
      [theme.breakpoints.up("lg")]: {
        maxWidth: constants.content.maxWidth,
      },
      [theme.breakpoints.up("xl")]: {
        maxWidth: constants.content.maxWidth,
      },
    },
    maxWidthXl: {
      maxWidth: constants.content.maxWidth,
      [theme.breakpoints.up("xl")]: {
        maxWidth: constants.content.maxWidth,
      },
    },
  },
  MuiButton: {
    root: {
      width: "190px",
      height: "50px",
      overflow: "hidden",
      boxShadow: "none !important",
      borderRadius: "4px",
      borderWidth: "1px !important",
      textDecoration: "none",
      textTransform: "uppercase",
      ...typography.button,
    },
    containedPrimary: {
      color: `${colors.base.black} !important`,
      borderColor: colors.base.black,
      backgroundColor: colors.base.white,
      "&:hover": {
        color: `${colors.base.white} !important`,
        borderColor: `${colors.base.black} !important`,
        backgroundColor: `${colors.base.black} !important`,
      },
    },
    containedSecondary: {
      color: `${colors.base.white} !important`,
      borderColor: colors.base.black,
      backgroundColor: colors.base.black,
      "&:hover": {
        color: `${colors.base.black} !important`,
        borderColor: `${colors.base.white} !important`,
        backgroundColor: `${colors.base.white} !important`,
      },
    },
    outlinedPrimary: {
      color: `${colors.base.black} !important`,
      borderColor: colors.grey[1],
      backgroundColor: "none",
      "&:hover": {
        color: `${colors.base.white} !important`,
        borderColor: `${colors.base.black} !important`,
        backgroundColor: `${colors.base.black} !important`,
      },
    },
    outlinedSecondary: {
      color: `${colors.base.white} !important`,
      borderColor: colors.base.white,
      backgroundColor: "none",
      "&:hover": {
        color: `${colors.grey[3]} !important`,
        borderColor: `${colors.base.white} !important`,
        backgroundColor: `${colors.base.white} !important`,
      },
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
  },
  MuiFormControl: {
    root: {
      width: "100%",
    },
  },
  MuiFormHelperText: {
    root: {
      ...typography.smallBody,
      fontSize: "0.9rem !important",
    },
    contained: {
      marginLeft: "0",
      marginRight: "0",
      textAlign: "right",
    },
  },
  MuiOutlinedInput: {
    root: {
      ...typography.body,
      overflow: "hidden",
      borderRadius: "4px !important", // Needs this to override default
      backgroundColor: colors.base.white,
      /* The margin bootom stops validation messages from pushing content down */
      //marginBottom: '1.5rem',
      "&.Mui-error": {
        marginBottom: "0",
      },
      "&:hover": {
        backgroundColor: colors.base.white,
      },
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: `${colors.grey[2]} !important`,
      },
      "&.Mui-focused fieldset": {
        borderColor: `${colors.grey[2]} !important`,
      },
      disabled: {
        backgroundColor: `colors.grey['-'] !important`,
      },
    },
    input: {
      padding: "0.5em 0.25em",
    },
  },
  MuiSelect: {
    root: {
      backgroundColor: colors.base.white,
      "&:hover": {
        backgroundColor: colors.base.white,
      },
    },
    icon: {
      top: "0",
    },
    disabled: {
      backgroundColor: `colors.grey['-'] !important`,
    },
  },
  MuiCheckbox: {
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      "& svg": {
        height: "1.5rem",
        width: "1.5rem",
      },
    },
    colorPrimary: {
      color: colors.base.black,
      "&.Mui-checked": {
        color: colors.base.black,
      },
    },
    colorSecondary: {
      color: colors.base.white,
      "&.Mui-checked": {
        color: colors.base.white,
      },
    },
  },
}

export default responsiveFontSizes(theme)
