// https://www.newsec.com/globalassets/images/design-manual/newsec-digital-design-manual.pdf
// https://nordicmorning.invisionapp.com/d/main?origin=v7#/console/19371598/404620474/preview?scrollOffset=0

import colors from "./colors"

export default {
  content: {
    maxWidth: "1110px",
  },
  hero: {
    minHeight: "500px",
    background: `linear-gradient(to bottom right, ${colors.grey[2]} 0%, ${colors.grey[1]} 100%)`,
  },
  heromobile: {
    minHeight: "500px",
    background: `linear-gradient(to bottom right, ${colors.blue[2]} 0%, ${colors.blue[1]} 100%)`,
  },
  heroCompact: {
    minHeight: "300px",
    background: `linear-gradient(to bottom right, ${colors.grey[2]} 0%, ${colors.grey[1]} 100%)`,
  },
  section: {
    minHeight: "300px",
  },
  heading: {
    maxWidth: "768px",
  },
  defaultImage: {
    background: `linear-gradient(to bottom right, ${colors.grey[2]} 0%, ${colors.grey[1]} 100%)`,
  },
}
