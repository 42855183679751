import React from "react"
import * as R from "ramda"
import Link from "../navigation/Link"

import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListSubheader from "@material-ui/core/ListSubheader"
import ListItem from "@material-ui/core/ListItem"

//import styles from './footer.module.scss'
import { makeStyles } from "@material-ui/core/styles"
import colors from "../theme/colors"

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: "2rem",
    color: colors.base.white,
    fontFamily: theme.typography.fontFamily,
  },
  sectionHeader: {
    color: colors.base.white,
    fontWeight: "bold",
    fontSize: "0.85rem",
    lineHeight: "1rem",
    marginBottom: "0.75rem",
    marginTop: "0.75rem",
    fontFamily: theme.typography.fontFamily,

    textDecoration: "none",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
    },
  },
  sectionHeaderText: {
    color: colors.base.white,
    fontWeight: "bold",
    fontSize: "0.85rem",
    fontFamily: theme.typography.fontFamily,
  },
  sectionLinks: {
    marginRight: "3em",
  },
  headingLink: {
    fontWeight: "bold",
    fontSize: "0.85rem",
    color: colors.base.white,
    fontFamily: theme.typography.fontFamily,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  groupLinkItem: {
    //margin: 0,
    //padding: 0
  },
  groupLink: {
    fontSize: "0.75rem",
    color: colors.base.white,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 300,
    letterSpacing: "0.4px",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))

const FooterLinks = props => {
  const { data, landingPages, contentPages, sitePages } = props

  const classes = useStyles()

  // The landing pages for the footer nav have no divider between categories (it's just one big list)
  // so all landing pages can be flatterned to a single list
  const navLandingPages = R.flatten(landingPages)
  // The landing pages need to occupy two columns (on most screens) so calculate how many enteries should be in each column
  const maxNavList = Math.ceil(navLandingPages.length / 2)

  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className={classes.sectionHeader}
              disableSticky
              disableGutters
            >
              <Link
                className={classes.headingLink}
                to="/toimitilat"
                text="TOIMITILAT"
              >
                TOIMITILAT
              </Link>
            </ListSubheader>
          }
        >
          {/*
                There should always be two columns of Nav links
                Using the max list size there can be two 
              */}
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item className={classes.sectionLinks}>
              {R.slice(0, maxNavList, navLandingPages).map((page, pIndex) => (
                <ListItem
                  component="span"
                  key={pIndex}
                  className={classes.groupLinkItem}
                  disableGutters
                  dense
                >
                  <Link
                    className={classes.groupLink}
                    to={page.to}
                    text={page.text}
                  >
                    {page.text}
                  </Link>
                </ListItem>
              ))}
            </Grid>
            <Grid item className={classes.sectionLinks}>
              {R.slice(maxNavList, navLandingPages.length, navLandingPages).map(
                (page, pIndex) => (
                  <ListItem
                    component="span"
                    key={pIndex}
                    className={classes.groupLinkItem}
                    disableGutters
                    dense
                  >
                    <Link
                      className={classes.groupLink}
                      to={page.to}
                      text={page.text}
                    >
                      {page.text}
                    </Link>
                  </ListItem>
                )
              )}
            </Grid>
          </Grid>
        </List>
      </Grid>

      <Grid item>
        <Grid
          className={classes.root}
          container
          direction="column"
          //justify="flex-start"
          //alignItems="flex-start"
        >
          <Grid item>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.sectionLinks}
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  className={classes.sectionHeader}
                  disableSticky
                  disableGutters
                >
                  <Link
                    className={classes.headingLink}
                    to="/tarinat"
                    text="TARINAT"
                  >
                    TARINAT
                  </Link>
                </ListSubheader>
              }
            >
              {contentPages.length > 0 &&
                contentPages.map((page, pIndex) => (
                  <ListItem
                    component="span"
                    key={pIndex}
                    className={classes.groupLinkItem}
                    disableGutters
                    dense
                  >
                    <Link
                      className={classes.groupLink}
                      to={page.to}
                      text={page.text}
                    >
                      {page.text}
                    </Link>
                  </ListItem>
                ))}
            </List>
          </Grid>

          <Grid item>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.sectionLinks}
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  className={classes.sectionHeader}
                  disableSticky
                  disableGutters
                >
                  {/*<Link
                    className={classes.headingLink}
                    to="https://www.newsec.fi"
                    text="YRITYS"
                  >
                    YRITYS
                  </Link>*/}
                </ListSubheader>
              }
            >
              {sitePages.length > 0 &&
                sitePages.map((page, pIndex) => (
                  <ListItem
                    component="span"
                    key={pIndex}
                    className={classes.groupLinkItem}
                    disableGutters
                    dense
                  >
                    <Link
                      className={classes.groupLink}
                      to={page.to}
                      text={page.text}
                    >
                      {page.text}
                    </Link>
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

// TODO app PropTypes

export default FooterLinks
