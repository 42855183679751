/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { ThemeProvider } from "@material-ui/styles"

import theme from "./theme/theme"

import Header from "./Header"
import Footer from "./footer/Footer"
/*import Chatbot from "./chatbot/Chatbot"*/
import "./layout.css"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  container: {
    flex: "1 0 auto",
  },
  footer: {
    flexShrink: "0",
  },
}))

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteDataQuery {
      site: contentfulSiteGlobal(activeVersion: { eq: "Kyllä" }) {
        linkedInUrl
        twitterUrl
        youTubeUrl
      }
    }
  `)

  const classes = useStyles()

  return (
    <ThemeProvider
      theme={{
        ...theme,
      }}
    >
      <Header data={data} />
      <div className={classes.container}>{children}</div>
      <div className={classes.footer}>
        <Footer data={data} />
      </div>
      {/*<Chatbot />*/}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
