import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Grid from "@material-ui/core/Grid"
import MuiAppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Slide from "@material-ui/core/Slide"
import Hidden from "@material-ui/core/Hidden"

import { makeStyles } from "@material-ui/core/styles"
import colors from "../theme/colors"
import constants from "../theme/constants"

import LandingPageLinks from "../../data/links-landingpages"
const ContentLinks = [{ to: "/tarinat", text: "Tarinat" },{ to: "/ota-yhteytta", text: "Ota yhteyttä" }]

import NavDrawer from "./NavDrawer"
import NavMenu from "./NavMenu"

import Icon from "../../assets/img/logo-99x29.png"

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  toolbarContainer: {
    maxWidth: "100%",
  },
  toolbar: {
    maxWidth: constants.content.maxWidth,
    margin: "auto",
  },
  homeLink: {
    margin: 0,
    padding: 0,
    textDecoration: "none",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logoContainer: {
    //flexGrow: 1,
    alignItems: "flex-end",
  },
  logo: {
    margin: "0.25rem 0.25rem 0 auto",
    width: "5.5rem",
  },
  title: {
    //lineHeight: '2.5em',
    margin: "0",
    fontSize: "0.7rem",
    textTransform: "uppercase",
    color: colors.grey["3"],
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      margin: "0.25rem auto 0.25rem 0.25rem",
      fontSize: "1.1rem",
    },
  },
  navDrawer: {},
  navMenu: {},
}))

const AppBar = () => {
  const classes = useStyles()

  const title = "toimitilahaku" //TODO take from gatsby config

  return (
    <React.Fragment>
      <HideOnScroll>
        <MuiAppBar>
          {/*position="static">*/}
          <div className={classes.toolbarContainer}>
            <Toolbar className={classes.toolbar}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={classes.logoContainer}
              >
                <Link className={classes.homeLink} to="/">
                  <img
                    id="appbar-logo"
                    className={classes.logo}
                    src={Icon}
                    alt="Newsec Logo"
                  />
                </Link>
                <Link className={classes.homeLink} to="/">
                  <span className={classes.title}>{title.toLowerCase()}</span>
                </Link>
              </Grid>
              {/* The cities for the menu should be taken from a datasource*/}
              {/* Nav drawer for xs screens */}
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.logoContainer}
              >
                <Hidden smUp>
                  <NavDrawer
                    menuItems={LandingPageLinks}
                    links={ContentLinks}
                  ></NavDrawer>
                </Hidden>
                {/*Inline for sm+ screens */}
                <Hidden xsDown>
                  <NavMenu
                    className={classes.navMenu}
                    menuItems={LandingPageLinks}
                    links={ContentLinks}
                  />
                </Hidden>
              </Grid>
            </Toolbar>
          </div>
        </MuiAppBar>
      </HideOnScroll>
      <div className={classes.offset} />
    </React.Fragment>
  )
}

export default AppBar

function HideOnScroll(props) {
  const { children } = props
  // See MUI doc for useScrollTrigger if this app needs to render in a iFrame (for some reason)
  //https://material-ui.com/components/app-bar/
  return (
    <Slide appear={false} direction="down" in={!useScrollTrigger()}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
}
