// This file is use to take the Newsec styles and map them onto the Material UI typography
// It also meens a common set of Typography styles (outside of what Material UI supports) can be defined
// https://nordicmorning.invisionapp.com/d/main?origin=v7#/console/19371598/404620474/inspect?scrollOffset=0
import colors from "./colors"

const fallbackFontfamilies = "Open Sans,sans-serif"

export default {
  defaultFontFamily: `Interstate,${fallbackFontfamilies}`,
  headline: {
    color: colors.base.white,
    fontFamily: `Interstate Compressed,${fallbackFontfamilies}`,
    fontWeight: "bold",
    textShadow: `0 3px 5px rgba(0,0,0,0.28)`,
    fontSize: "3rem",
    lineHeight: 1.2,
    textTransform: "uppercase",
    hyphens: "auto",
    /* The following queries are reuired to override 
        MUI responsive font default styles */
    "@media (min-width: 600px)": {
      fontSize: "3.5rem",
    },
    "@media (min-width: 960px)": {
      fontSize: "5rem",
    },
  },
  h1: {
    fontFamily: `Interstate Compressed,${fallbackFontfamilies}`,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "5rem",
    lineHeight: 1.2,
  },
  h2: {
    fontFamily: `Interstate Compressed,${fallbackFontfamilies}`,
    fontStyle: "normal",
    fontWeight: "bold",
    letterSpacing: "0.3px",
    fontSize: "3.8rem",
    lineHeight: 1.2,
  },
  h3: {
    fontFamily: `Interstate Compressed,${fallbackFontfamilies}`,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "2.8rem",
    lineHeight: 1.2,
  },
  h4: {
    fontFamily: `Interstate Compressed,${fallbackFontfamilies}`,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "2.2rem",
    lineHeight: 1.2,
  },
  h5: {
    fontFamily: `Interstate,${fallbackFontfamilies}`,
    fontStyle: "normal",
    fontWeight: 300,
    letterSpacing: "-0.3px",
    fontSize: "2rem",
    lineHeight: 1.3,
  },
  h6: {
    fontFamily: `Interstate,${fallbackFontfamilies}`,
    fontStyle: "normal",
    fontWeight: "bold",
    letterSpacing: "0.3px",
    fontSize: "2rem",
    lineHeight: 1.3,
  },
  intro: {
    fontFamily: `Interstate,${fallbackFontfamilies}`,
    fontWeight: 300,
    letterSpacing: "0.4px",
    fontSize: "1.4rem",
    lineHeight: 1.4,
  },

  body: {
    fontFamily: `Interstate,${fallbackFontfamilies}`,
    fontSize: "1.05rem",
    fontWeight: 300,
    letterSpacing: "0.5px",
    lineHeight: 1.2,
  },
  smallBody: {
    fontFamily: `Interstate,${fallbackFontfamilies}`,
    fontStyle: "normal",
    fontSize: "0.9rem",
    letterSpacing: "0.25px",
    lineHeight: 1.5,
  },
  xsmallBody: {
    fontFamily: `Interstate,${fallbackFontfamilies}`,
    fontStyle: "normal",
    fontWeight: 300,
    letterSpacing: "0.1px",
    fontSize: "1rem",
    lineHeight: 1.35,
    opacity: 0.7,
  },
  breadcrumbLink: {
    fontFamily: `Interstate,${fallbackFontfamilies}`,
    fontStyle: "normal",
    fontWeight: 300,
    letterSpacing: "0.1px",
    fontSize: 12,
    lineHeight: "16px",
    opacity: 1,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  quote: {
    fontFamily: `Interstate,${fallbackFontfamilies}`,
    color: colors.blue[2],
    fontStyle: "italic",
    fontWeight: 300,
    letterSpacing: "-0.21px",
    fontSize: "1.2rem",
    lineHeight: 1.2,
    opacity: 0.9,
    "@media (min-width: 960px)": {
      fontSize: "2rem",
    },
  },
  button: {
    fontFamily: `Interstate,${fallbackFontfamilies}`,
    fontWeight: 300,
    letterSpacing: "0.5px",
    lineHeight: 1,
    fontSize: "1rem",
    /* The following queries are reuired to override 
        MUI responsive font default styles */
    "@media (min-width: 1280px)": {
      fontSize: "1.2rem",
    },
    "@media (min-width: 960px)": {
      fontSize: "1.1rem",
    },
    "@media (min-width: 600px)": {
      fontSize: "1rem",
    },
  },
  link: {},
  footerLink: {},
}
